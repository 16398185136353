iframe {
  border: 0;
}

form {
	margin: 0;
	padding: 0;
}
/*   Formatki dla wszystkich obiektów  */
.form-control:-ms-input-placeholder {
  color: #bbb;
  opacity: 1;
  font-style: italic;
  font-weight: normal;
}
.form-control::-ms-input-placeholder {
  color: #bbb;
  opacity: 1;
  font-style: italic;
  font-weight: normal;
}
.form-control::placeholder {
  color: #bbb;
  opacity: 1;
  font-style: italic;
  font-weight: normal;
}
.we-form-label {
  display: inline-block;
  margin-bottom: .5rem; 
}
.we-form-check-input-group-list { 
  margin-top: .5rem;
}
.we-nowrap { 
  white-space: nowrap; 
}
.we-droparea {
	border: 2px dashed #007bff;
	border-radius: 0px;
	padding: 0px;
	margin: 0px;
	min-height: 150px;
	width: 100%;
	position: relative;
}
.we-droparea:before {
	content: "Złap myszką pliki foto i upuść je tutaj";
	position: absolute;
	left: 50%;
	width: 300px;
	margin-left: -100px;
	margin-top: -10px;
	top: 20%;
	height: 20px;
}
/*    dla wszystkich liczników      */
.progress-count {
    display: inline-block;
    padding: 0px;
    text-align: center;
}
.progress-count label {
    display: block;
}
.we-content-menu-top {
	width:100%;
	max-width:100%;
	left:0;
	z-index:1000;
}
/*    szybkie linki      */
.we-content-skip-links {
	position:absolute;
	top:0;
	padding-left: 0;
	list-style: none;
}
.we-content-skip-links a,
.we-content-skip-links a:visited {
	position:absolute;
	top:0;
	left:-9000rem;
	display:block;
	text-align:center;
	white-space:nowrap;
	text-decoration:none;
	opacity:0;
  padding:1rem 2rem;
  background-color:#333;
	color:#fff;
	font-size:1rem;
	font-weight:bold;
	z-index:10000;
	-webkit-transition:opacity 0.3s ease 0s;
	transition:opacity 0.3s ease 0s;
}
.we-content-skip-links a:hover,
.we-content-skip-links a:focus,
.we-content-skip-links a:active,
.we-content-skip-links a:visited:hover,
.we-content-skip-links a:visited:focus,
.we-content-skip-links a:visited:active {
	left:0;
	opacity:1
}

.we-ratio {
  position: relative;
  width: 100%;
}

.we-ratio::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.we-ratio.we-ratio-21by9::before { padding-top: 42.857143%; }
.we-ratio.we-ratio-16by9::before { padding-top: 56.25%; }
.we-ratio.we-ratio-4by3::before {  padding-top: 75%;}
.we-ratio.we-ratio-100::before, .we-ratio::before.we-ratio-1by1 { padding-top: 100%; }
.we-ratio.we-ratio-200::before { padding-top: 200%; }
.we-ratio.we-ratio-195::before { padding-top: 195%; }
.we-ratio.we-ratio-190::before { padding-top: 190%; }
.we-ratio.we-ratio-185::before { padding-top: 185%; }
.we-ratio.we-ratio-180::before { padding-top: 180%; }
.we-ratio.we-ratio-175::before { padding-top: 175%; }
.we-ratio.we-ratio-170::before { padding-top: 170%; }
.we-ratio.we-ratio-165::before { padding-top: 165%; }
.we-ratio.we-ratio-160::before { padding-top: 160%; }
.we-ratio.we-ratio-155::before { padding-top: 155%; }
.we-ratio.we-ratio-150::before { padding-top: 150%; }
.we-ratio.we-ratio-145::before { padding-top: 145%; }
.we-ratio.we-ratio-140::before { padding-top: 140%; }
.we-ratio.we-ratio-135::before { padding-top: 135%; }
.we-ratio.we-ratio-130::before { padding-top: 130%; }
.we-ratio.we-ratio-125::before { padding-top: 125%; }
.we-ratio.we-ratio-120::before { padding-top: 120%; }
.we-ratio.we-ratio-115::before { padding-top: 115%; }
.we-ratio.we-ratio-105::before { padding-top: 105%; }
.we-ratio.we-ratio-095::before { padding-top:  95%; }
.we-ratio.we-ratio-090::before { padding-top:  90%; }
.we-ratio.we-ratio-085::before { padding-top:  85%; }
.we-ratio.we-ratio-080::before { padding-top:  80%; }
.we-ratio.we-ratio-075::before { padding-top:  75%; }
.we-ratio.we-ratio-070::before { padding-top:  70%; }
.we-ratio.we-ratio-065::before { padding-top:  65%; }
.we-ratio.we-ratio-060::before { padding-top:  60%; }
.we-ratio.we-ratio-055::before { padding-top:  55%; }
.we-ratio.we-ratio-050::before { padding-top:  50%; }
.we-ratio.we-ratio-045::before { padding-top:  45%; }
.we-ratio.we-ratio-040::before { padding-top:  40%; }
.we-ratio.we-ratio-035::before { padding-top:  35%; }
.we-ratio.we-ratio-030::before { padding-top:  30%; }
.we-ratio.we-ratio-025::before { padding-top:  25%; }
.we-ratio.we-ratio-020::before { padding-top:  20%; }
.we-ratio.we-ratio-015::before { padding-top:  15%; }
.we-ratio.we-ratio-010::before { padding-top:  10%; }
.we-ratio.we-ratio-005::before { padding-top:   5%; }

.we-ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/*     Mapa      */
.we-mapcreator,
.we-mapcreator .we-map-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  min-height: 250px;
}
.we-mapcreator .we-map-content::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.we-mapcreator.we-mapcreator-getlatlon { 
  cursor: crosshair; 
}
.we-mapcreator .we-map-content .we-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.we-mapcreator.we-mapcreator-responsive-21by9 .we-map-content::before { padding-top: 42.857143%; }
.we-mapcreator.we-mapcreator-responsive-16by9 .we-map-content::before { padding-top: 56.25%; }
.we-mapcreator.we-mapcreator-responsive-4by3 .we-map-content::before {  padding-top: 75%;}
.we-mapcreator.we-mapcreator-responsive-100 .we-map-content::before, .we-mapcreator.we-mapcreator-responsive-1by1 .we-map-content::before { padding-top: 100%; }
.we-mapcreator.we-mapcreator-responsive-200 .we-map-content::before { padding-top: 200%; }
.we-mapcreator.we-mapcreator-responsive-195 .we-map-content::before { padding-top: 195%; }
.we-mapcreator.we-mapcreator-responsive-190 .we-map-content::before { padding-top: 190%; }
.we-mapcreator.we-mapcreator-responsive-185 .we-map-content::before { padding-top: 185%; }
.we-mapcreator.we-mapcreator-responsive-180 .we-map-content::before { padding-top: 180%; }
.we-mapcreator.we-mapcreator-responsive-175 .we-map-content::before { padding-top: 175%; }
.we-mapcreator.we-mapcreator-responsive-170 .we-map-content::before { padding-top: 170%; }
.we-mapcreator.we-mapcreator-responsive-165 .we-map-content::before { padding-top: 165%; }
.we-mapcreator.we-mapcreator-responsive-160 .we-map-content::before { padding-top: 160%; }
.we-mapcreator.we-mapcreator-responsive-155 .we-map-content::before { padding-top: 155%; }
.we-mapcreator.we-mapcreator-responsive-150 .we-map-content::before { padding-top: 150%; }
.we-mapcreator.we-mapcreator-responsive-145 .we-map-content::before { padding-top: 145%; }
.we-mapcreator.we-mapcreator-responsive-140 .we-map-content::before { padding-top: 140%; }
.we-mapcreator.we-mapcreator-responsive-135 .we-map-content::before { padding-top: 135%; }
.we-mapcreator.we-mapcreator-responsive-130 .we-map-content::before { padding-top: 130%; }
.we-mapcreator.we-mapcreator-responsive-125 .we-map-content::before { padding-top: 125%; }
.we-mapcreator.we-mapcreator-responsive-120 .we-map-content::before { padding-top: 120%; }
.we-mapcreator.we-mapcreator-responsive-115 .we-map-content::before { padding-top: 115%; }
.we-mapcreator.we-mapcreator-responsive-105 .we-map-content::before { padding-top: 105%; }
.we-mapcreator.we-mapcreator-responsive-095 .we-map-content::before { padding-top:  95%; }
.we-mapcreator.we-mapcreator-responsive-090 .we-map-content::before { padding-top:  90%; }
.we-mapcreator.we-mapcreator-responsive-085 .we-map-content::before { padding-top:  85%; }
.we-mapcreator.we-mapcreator-responsive-080 .we-map-content::before { padding-top:  80%; }
.we-mapcreator.we-mapcreator-responsive-075 .we-map-content::before { padding-top:  75%; }
.we-mapcreator.we-mapcreator-responsive-070 .we-map-content::before { padding-top:  70%; }
.we-mapcreator.we-mapcreator-responsive-065 .we-map-content::before { padding-top:  65%; }
.we-mapcreator.we-mapcreator-responsive-060 .we-map-content::before { padding-top:  60%; }
.we-mapcreator.we-mapcreator-responsive-055 .we-map-content::before { padding-top:  55%; }
.we-mapcreator.we-mapcreator-responsive-050 .we-map-content::before { padding-top:  50%; }
.we-mapcreator.we-mapcreator-responsive-045 .we-map-content::before { padding-top:  45%; }
.we-mapcreator.we-mapcreator-responsive-040 .we-map-content::before { padding-top:  40%; }
.we-mapcreator.we-mapcreator-responsive-035 .we-map-content::before { padding-top:  35%; }
.we-mapcreator.we-mapcreator-responsive-030 .we-map-content::before { padding-top:  30%; }
.we-mapcreator.we-mapcreator-responsive-025 .we-map-content::before { padding-top:  25%; }
.we-mapcreator.we-mapcreator-responsive-020 .we-map-content::before { padding-top:  20%; }
.we-mapcreator.we-mapcreator-responsive-015 .we-map-content::before { padding-top:  15%; }
.we-mapcreator.we-mapcreator-responsive-010 .we-map-content::before { padding-top:  10%; }
.we-mapcreator.we-mapcreator-responsive-005 .we-map-content::before { padding-top:   5%; }
.we-mapcreator.we-mapcreator-responsive-full-height, .we-mapcreator.we-mapcreator-responsive-full-height .we-map-content { height:100%; }
.we-mapcreator.we-mapcreator-responsive-full-height .we-map-content::before { padding-top: 0px; }

.we-mapcreator .ol-attribution.ol-uncollapsible {
  font-size: 0.75rem;
}

.we-mapcreator .we-map-search-position {
  position: absolute;
  bottom: 3rem;
  right: 1rem;
  z-index: 100;
 
}

.we-mapcreator .we-map-mouse-position {
  position: absolute;
  top: .5rem;
  left: 3rem;
  z-index: 100;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.4);
}
.we-mapcreator .we-map-mouse-position .custom-mouse-position {
  background-color: rgba(0, 60, 136, 0.5);
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  color: #eee;
}
.we-mapcreator .ol-scale-line {
  left: 3rem;
  bottom: 0.5rem;
  background: transparent;
  /*cursor: pointer;*/
}
.we-mapcreator .ol-scale-line .ol-scale-line-inner {
  margin: 3px;
  border: 2px solid #666;
  border-top: none;
  color: #666;
}
.we-mapcreator .we-map-menu {
  position: absolute;
  z-index: 100;
  background-color: #fff;
  top: .5rem;
  right: .5rem;
  padding: 0px;
  margin: 0px;
  color: #000;
  cursor: auto;
}
@media (max-width: 576px) {
  .we-mapcreator .we-map-menu {
    position: relative;
    z-index: 100;
    padding: 0px;
    margin: 0px;
    right:0px;
    top: 0rem;
  }
}
@media (max-width: 610px) {  
  /* ukrywamy zoom slider gdyż jego wysokośc koluduje z minimapą dla małych rozdzielczości */
  .we-mapcreator .ol-zoomslider {
    display: none;
  }
}
.we-mapcreator .ol-zoomslider-thumb { 
  cursor: n-resize; 
}
.we-mapcreator .accordion p {
  font-size: 0.8rem;
}
.we-mapcreator .accordion .btn {
  width: 100%;
  text-align: left;
  color: #fff;
  padding: .375rem .75rem;
  text-decoration: none;
  font-size: 0.8rem;
  background-color: rgba(0,60,136,0.7);
}
.we-mapcreator .accordion label {
  font-size: 0.8rem;
}
.we-mapcreator .accordion .form-check-input {
  margin-top: .25rem;
}
.we-mapcreator .accordion .form-check-label {
  white-space: nowrap;
  cursor: pointer;
}
.we-mapcreator .accordion .form-check {
  padding-left: 1rem;
  line-height: 1.2;
}
.we-mapcreator .we-map-layer:checked + .form-check-label {
  font-weight: bold;
}
.we-mapcreator .ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.2));
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  bottom: 50px;
  left: -50px;
  min-width: 450px;
  color: #000;
  font-size: 1rem;
}
.we-mapcreator .ol-popup:after,
.we-mapcreator .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.we-mapcreator .ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.we-mapcreator .ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.we-mapcreator .ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  color: #000;
  cursor: pointer;
}
.we-mapcreator .ol-popup .ol-popup-title {
  font-size: 1rem;
  font-weight: bold;
}
.we-mapcreator .ol-popup .ol-popup-content {
  font-size: 0.9rem;
  padding-bottom: 4px;
}
.we-mapcreator .ol-popup-closer:after {
  content: "✖";
}


.card-body {
	padding: 15px;
}

.card-body + .card-body {
	padding-top: 0px;
}

.card-body .form-group {
	background-color:rgba(0, 0, 0, 0.03);
}

.card-body .form-group:hover {
	background-color:rgba(0, 0, 0, 0.07);
}

.card-body .form-group:nth-of-type(2n) {
	background-color:rgba(0, 0, 0, 0.01);
}

.card-body .form-group:hover:nth-of-type(2n) {
	background-color:rgba(0, 0, 0, 0.07);
}

.card-body .form-group.no-publish {
	background-color:rgba(255, 0, 0, 0.15);
}

.card-body .form-group.no-publish:hover {
	background-color:rgba(255, 0, 0, 0.2);
}

.card-body .form-group.no-publish:nth-of-type(2n) {
	background-color:rgba(255, 0, 0, 0.07);
}

.card-body .form-group.no-publish:hover:nth-of-type(2n) {
	background-color:rgba(255, 0, 0, 0.2);
}

.nav-tabs {
	border-bottom: 1px solid #007bff;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	border-color: #007bff #007bff #fff;
}

.nav-tabs .nav-link {
	border-bottom: 1px solid #007bff;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	border-color: #007bff #007bff #007bff;
}

.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover {
	border-color: #007bff #007bff #fff;
}

.nav-tabs .nav-link.active.we-change-data {
	color: #ff0000;
}

.table-primary, .table-primary > td, .table-primary > th {
	background-color: #2e6196;
}

.we-decoration-border-top:not(.we-decoration-border-top-disable) {
	border-top: 2px solid #337ab7;
}

.we-decoration-border-bottom:not(.we-decoration-border-bottom-disable) {
	border-bottom: 2px solid #337ab7;
}

.we-decoration-border-right:not(.we-decoration-border-right-disable) {
	border-right: 2px solid #337ab7;
}

.we-decoration-border-left:not(.we-decoration-border-left-disable) {
	border-left: 2px solid #337ab7;
}

/* korekta bo button wewnatrz tabeli ma 0.25rem a tabela 0.3 w oryginale */
.table-sm td, .table-sm th {
	padding: .25rem;
}


.we-width-minimum {
	width: 1%;
}

.we-font-size-450rem { font-size: 4.50rem }
.we-font-size-400rem { font-size: 4.00rem }
.we-font-size-350rem { font-size: 3.50rem }
.we-font-size-300rem { font-size: 3.00rem }
.we-font-size-250rem { font-size: 2.50rem }
.we-font-size-200rem { font-size: 2.00rem }
.we-font-size-195rem { font-size: 1.95rem }
.we-font-size-190rem { font-size: 1.90rem }
.we-font-size-185rem { font-size: 1.85rem }
.we-font-size-180rem { font-size: 1.80rem }
.we-font-size-175rem { font-size: 1.75rem }
.we-font-size-170rem { font-size: 1.70rem }
.we-font-size-165rem { font-size: 1.65rem }
.we-font-size-160rem { font-size: 1.60rem }
.we-font-size-155rem { font-size: 1.55rem }
.we-font-size-150rem { font-size: 1.50rem }
.we-font-size-145rem { font-size: 1.45rem }
.we-font-size-140rem { font-size: 1.40rem }
.we-font-size-135rem { font-size: 1.35rem }
.we-font-size-130rem { font-size: 1.30rem }
.we-font-size-125rem { font-size: 1.25rem }
.we-font-size-120rem { font-size: 1.20rem }
.we-font-size-115rem { font-size: 1.15rem }
.we-font-size-110rem { font-size: 1.10rem }
.we-font-size-105rem { font-size: 1.05rem }
.we-font-size-100rem { font-size: 1.00rem }
.we-font-size-095rem { font-size: 0.95rem }
.we-font-size-090rem { font-size: 0.90rem }
.we-font-size-085rem { font-size: 0.85rem }
.we-font-size-080rem { font-size: 0.80rem }
.we-font-size-075rem { font-size: 0.75rem }
.we-font-size-070rem { font-size: 0.70rem }
.we-font-size-065rem { font-size: 0.65rem }
.we-font-size-060rem { font-size: 0.60rem }
.we-font-size-055rem { font-size: 0.55rem }
.we-font-size-050rem { font-size: 0.50rem }

.we-btn-extra-sm {
    padding: .1rem .25rem;
    font-size: .75rem;
    line-height: 1.5;
    border-radius: .2rem;
}
